* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-size: 1em;
  color: whitesmoke;
}

.course {
  margin-top: 20px;
  border: none;
  border-radius: 3px;
  padding: 0.5em;
}

a {
  color: whitesmoke;
}

.button {
  background: #1565c0;
  color: whitesmoke;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin: 0;
}

.button img {
  max-width: 100%;
  max-height: 100%;
}

.course {
  max-width: 30em;
  margin-right: auto;
  margin-left: auto;
}

#day-date {
  font-size: 2.5em;
  padding: 0;
  margin: 0;
}

#cal-view {
  width: 100%;
  display: grid;
  grid-template: 'status status status' 2em 'prev today next' 3em 'day day day' auto / 1fr 1fr 1fr;
}

#button-prev {
  grid-area: prev;
}

#button-today {
  grid-area: today;
}

#button-next {
  grid-area: next;
}

#button-next,
#button-prev {
  margin-left: 0.7em;
  margin-right: 0.7em;
}

#load-status {
  grid-area: status;
}

.view-name {
  font-size: 2.5em;
}

.force-reload {
  text-decoration: none;
  display: inline-block;
  margin: 2rem 1rem;
}

.input-text {
  display: block;
  margin-right: auto;
  margin-left: auto;
  background: #212121;
  color: white;
  width: 100%;
  padding: 0.5em;
  border: white 1px solid;
  border-radius: 0.2em;
  max-width: 40rem;
}

.url-label {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.day-view {
  grid-area: day;
  margin-right: 1vmin;
  margin-left: 1vmin;
  min-height: 50vh;
}

.day-view:not(.current-day) {
  display: none;
}

.current-day {
  background: rgba(255, 255, 255, 0.05);
}

.course {
  display: grid;
  grid-template:
    'start name'
    'pipe group'
    'end location'/ 3em auto;
}

.current-course {
  border: 1px dashed whitesmoke;
}

.course-start {
  grid-area: start;
}

.course-pipe {
  grid-area: pipe;
}

.course-end {
  grid-area: end;
}

.course-name {
  grid-area: name;
}

.course-group {
  color: rgba(255, 255, 255, 0.75);
  grid-area: group;
}

.course-location {
  grid-area: location;
}
