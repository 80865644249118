.courses .course {
  grid-column: 2;
  margin: auto;
  max-width: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  grid-template:
    'start infos'
    'pipe infos'
    'end infos'/ 3em auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.course::-webkit-scrollbar {
  display: none;
}

.courses .course-compact {
  grid-template: 'start end' 1.5em 'infos infos' auto;
}

.courses .no-course {
  grid-column: 2;
  width: 100%;
  text-align: center;
}

.courses .course-start {
  margin-top: 0;
  margin-bottom: auto;
}

.courses .course-location,
.courses .course-end {
  margin-top: auto;
  margin-bottom: 0;
}

.course-infos {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 100%;
  grid-area: infos;
}

.course-compact .course-end,
.course-compact .course-start {
  margin: auto;
}

.hourMark {
  grid-column: 1;
  text-align: right;
  /* padding-right: 0.2em; */
}

.day-view-refresh {
  max-width: 100%;
  height: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
}

.courses .course-name,
.courses .course-location {
  margin-bottom: 0.3em;
}

.courses .course-name {
  margin-top: 0.1em;
}

.courses {
  display: grid;
  width: 100%;
  grid-template-rows: 2em repeat(48, 1.15em);
  column-gap: 0.2em;
}

.one-day-view .courses {
  grid-template-columns: 3rem auto;
}

.three-days-view .courses {
  grid-template-columns: 3rem repeat(3, 1fr);
}

.week-view .courses {
  grid-template-columns: auto repeat(7, 1fr);
}

.day-date {
  font-size: 1.5em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flat-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.flat-bot {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#cal-view-refresh {
  margin-right: 0.5em;
  margin-left: 0.5em;
}
